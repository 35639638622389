<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M16.0003 29.3334C8.63633 29.3334 2.66699 23.364 2.66699 16C2.66699 8.63602 8.63633 2.66669 16.0003 2.66669C23.3643 2.66669 29.3337 8.63602 29.3337 16C29.3337 23.364 23.3643 29.3334 16.0003 29.3334ZM14.671 21.3334L24.0977 11.9054L22.2123 10.02L14.671 17.5627L10.899 13.7907L9.01366 15.676L14.671 21.3334Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
};
</script>
